import React from 'react'
import Image from 'gatsby-image'
import PropTypes from 'prop-types'

const ImageBlock = ({ image, desktopImage, hasPadding = false }) => {
  return (
    <div className={`${hasPadding ? 'p-4' : 'p-0'}`}>
      <Image
        className={`${desktopImage ? 'md:hidden' : ''}`}
        fluid={image.fluid}
        alt={image.title}
      />
      {desktopImage && (
        <Image
          className="md:block hidden"
          fluid={desktopImage.fluid}
          alt={desktopImage.title}
        />
      )}
    </div>
  )
}

ImageBlock.propTypes = {
  props: PropTypes.shape({
    image: PropTypes.object.isRequired,
    desktopImage: PropTypes.object,
    hasPadding: PropTypes.bool
  })
}

export default ImageBlock
