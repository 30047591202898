import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import RichText from '../../../../utils/richTextUtils'

const Testimonial = ({ date, person, text }) => {
  return (
    <div className="flex flex-col justify-center pb-16 lg:mx-6">
      <div className="flex flex-col h-full bg-white p-4 shadow-lg">
        {person?.personName && (
          <div className="flex mb-4">
            <div>
              <Image fluid={person.image.fluid} className="w-20" />
            </div>
            <div className="flex flex-col flex-1 justify-center text-left pl-2">
              <div className="text-lg text-purple pb-1 font-bold">
                {person.personName}
              </div>
              <div className="text-color-medium-grey text-base font-normal">
                {date}
              </div>
            </div>
          </div>
        )}
        {text.json && (
          <div className="flex flex-col hr-primary-base flex-1">
            <div className="flex-1 text-left">
              <RichText richTextDocument={text.json} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Testimonial.propTypes = {
  props: PropTypes.shape({
    date: PropTypes.string,
    person: PropTypes.object,
    text: PropTypes.object
  })
}

export default Testimonial
