import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import RichText from '../../../../utils/richTextUtils'

const Person = ({ personName, image, bio, largeImage }) => {
  return (
    <div className="lg:px-6">
      {image && <Image fluid={image.fluid} className="w-32 mx-auto mb-4" />}
      {personName && (
        <div className="color-purple font-bold text-lg mb-2">{personName}</div>
      )}
      {bio && (
        <div className="mb-10">
          <RichText richTextDocument={bio.json} />
        </div>
      )}
      {largeImage && (
        <Image fluid={largeImage.fluid} fadeIn={false} className="w-36" />
      )}
    </div>
  )
}

Person.propTypes = {
  props: PropTypes.shape({
    personName: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    bio: PropTypes.object.isRequired,
    largeImage: PropTypes.object.isRequired
  })
}

export default Person
