import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../SEO'
import HeroCardContainer from './Cards/HeroCard/HeroCardContainer'
import BasicCard from './Cards/BasicCard/BasicCard'
import CarouselCard from './Cards/CarouselCard/CarouselCard'

const LandingContainer = ({
  data: {
    content: {
      cards,
      title,
      description: { description }
    }
  },
  location
}) => {
  return (
    <div className="newstyle">
      <SEO title={title} description={description} location={location} />
      {cards?.map((card, i) => (
        <div key={i}>
          {
            // TODO: Object is constructed on each iteration. It should be imported. PH-2825
            {
              ContentfulHeroCard: <HeroCardContainer {...card} />,
              ContentfulBasicCard: <BasicCard {...card} />,
              ContentfulCarouselCard: <CarouselCard {...card} />
            }[card.__typename]
          }
        </div>
      ))}
    </div>
  )
}

export const query = graphql`
  query($id: String!) {
    content: contentfulLandingPage(id: { eq: $id }, node_locale: { eq: "en-US" }) {
      description {
        description
      }
      title
      cards {
        ... on ContentfulHeroCard {
          ...HeroCardFragment
        }
        ... on ContentfulBasicCard {
          ...BasicCardFragment
        }
        ... on ContentfulCarouselCard {
          ...CarouselCardFragment
        }
      }
    }
  }
`

export default LandingContainer
