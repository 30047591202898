import React from 'react'
import PropTypes from 'prop-types'
import RichText from '../../../utils/richTextUtils'
import LandingCta from '../common/LandingCta'
import { COLORS } from '../../../constants/colors'

const CardWrapper = ({
  eyebrow,
  headline,
  body,
  backgroundColor = COLORS.WHITE,
  cta,
  children
}) => {
  return (
    <div
      className={`bg-color-${backgroundColor} bg-gp-${backgroundColor} relative`}
    >
      {headline && (
        <div className="w-full lg:w-1/2 mx-auto text-center pt-6 pb-2 px-4">
          {eyebrow && <h4>{eyebrow}</h4>}
          {headline && <h2 className="h1 mb-4">{headline}</h2>}
          {body && <RichText richTextDocument={body.json} />}
        </div>
      )}
      {children && children}
      {cta && (
        <div className="flex justify-center pt-4 pb-10">
          <LandingCta {...cta} />
        </div>
      )}
    </div>
  )
}

CardWrapper.propTypes = {
  eyebrow: PropTypes.string,
  headline: PropTypes.string,
  body: PropTypes.object,
  backgroundColor: PropTypes.oneOf([
    COLORS.WHITE,
    COLORS.PALE_PURPLE,
    COLORS.LILAC,
    COLORS.PALE_GREY,
    COLORS.LIGHT_GREY,
    COLORS.LIGHT_TEAL,
    COLORS.PEACH,
    COLORS.VIOLET,
    COLORS.GRAPEFRUIT
  ]).isRequired,
  cta: PropTypes.object
}

export default CardWrapper
