import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LandingCta from '../../common/LandingCta'
import { heroCardText } from 'goodpath-common'

// Dynamically loop through the condition text and appends to the end of the headline
export const DynamicHeroCardText = ({
  headlineText = '',
  isDynamic = false,
  bodyText,
  fullWidthBodyText = true,
  cta
}) => {
  const [dynamicText] = useState(heroCardText[0])

  return (
    <div
      className={`h-full flex flex-col justify-center xl:px-32 lg:px-20 md:p-24 px-8 max-w-full mx-auto sm:p-16 md:p-32 lg:p-40 xl:p-64 ${
        fullWidthBodyText ? 'py-40' : 'py-12'
      }`}
    >
      <h1 className="text-3xl leading-tight">
        {headlineText}
        {isDynamic && (
          <>
            <br />
            <strong> {dynamicText}</strong>
          </>
        )}
      </h1>
      {bodyText && (
        <p
          className={`pb-4 md:pb-10 text-color-charcoal-grey ${
            fullWidthBodyText ? 'mr-0' : 'mr-32'
          } md:mr-0`}
        >
          {bodyText}
        </p>
      )}
      {cta && (
        <div className="inline-flex">
          <LandingCta {...cta} />
        </div>
      )}
    </div>
  )
}

DynamicHeroCardText.propTypes = {
  headlineText: PropTypes.string.isRequired,
  isDynamic: PropTypes.bool,
  bodyText: PropTypes.string,
  fullWidthBodyText: PropTypes.bool,
  cta: PropTypes.object
}

export default DynamicHeroCardText
