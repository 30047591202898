import React, { useRef } from 'react'
import Testimonial from './Testimonial'
import Slider from 'react-slick'
import CardWrapper from '../CardWrapper'
import Person from './Person'

const CarouselCard = ({
  headline,
  backgroundColor,
  body,
  cta,
  eyebrow,
  items,
  hasArrows,
  hasDots,
  slidesToShow
}) => {
  const slider = useRef()

  const settings = {
    dots: hasDots && slidesToShow < items?.length,
    infinite: false,
    slidesToShow: slidesToShow,
    className: 'align-left',
    arrows: hasArrows && slidesToShow < items?.length,
    slidesToScroll: slidesToShow,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: hasDots,
          arrows: hasArrows
        }
      }
    ]
  }

  const desktopWidth = slidesToShow === 1 ? 'w-1/3' : 'w-full'

  return (
    <CardWrapper
      headline={headline}
      backgroundColor={backgroundColor}
      body={body}
      cta={cta}
      eyebrow={eyebrow}
    >
      <div className="flex justify-center items-center">
        <div
          className={`inline-block px-6 text-center md:${desktopWidth} w-full`}
        >
          <Slider ref={slider} {...settings}>
            {items?.map(item => {
              return (
                <div key={item.id}>
                  {
                    // TODO: Object is constructed on each iteration. It should be imported. PH-2825
                    {
                      ContentfulTestimonial: <Testimonial {...item} />,
                      ContentfulPersonData: <Person {...item} />
                    }[item.__typename]
                  }
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </CardWrapper>
  )
}

export default CarouselCard
