import React from 'react'
import RichText from '../../../../../utils/richTextUtils'
import LandingCta from '../../../common/LandingCta'
import PropTypes from 'prop-types'

const TextCtaBlock = ({ text, textAlignment, cta }) => {
  textAlignment = textAlignment || 'center'
  return (
    <div className="px-6 pt-6 text-center h-full flex flex-col justify-center">
      {text?.json && (
        <div className={`text-${textAlignment.toLowerCase()}`}>
          <RichText richTextDocument={text.json} />
        </div>
      )}
      {cta && (
        <div className="px-6 pt-6 pb-10 lg:p-10 flex flex-col justify-center items-center">
          <LandingCta {...cta} />
        </div>
      )}
    </div>
  )
}

TextCtaBlock.propTypes = {
  text: PropTypes.object,
  cta: PropTypes.object
}

export default TextCtaBlock
