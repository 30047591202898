// Collapsible.js
import ChevronDown from '../../../assets/chevron-down.svg'
import ChevronUp from '../../../assets/chevron-up.svg'
import React, { useState, useEffect } from 'react'

export default function Collapsible({
  title,
  titleRenderer = null,
  children,
  styles = {},
  popover = null,
  expanded,
  IconsContainer = null,
  key = '',
  headerClassName = '',
  headerContent = false // you can pass here in headerContent the html for the header you want
}) {
  const [open, toggle] = useState(false)

  // Toggle open if 'expanded' prop is passed
  useEffect(() => {
    toggle(expanded)
  }, [expanded])

  const { header, content, outer, h3 } = styles
  const headerRootClassName = open
    ? `flex flex-row items-center cursor-pointer is-open ${headerClassName}`
    : `flex flex-row items-center cursor-pointer ${headerClassName}`

  const h3Style = h3 || 'heading--3'

  return headerContent ? (
    <div key={key} className={outer}>
      <div className={header} onClick={(e) => toggle(!open)}>
        {headerContent}
        <div className="w-1/6 md:w-auto m-auto">
          {open ? (
            <ChevronUp width="24" height="24" className="cursor-pointer" />
          ) : (
            <ChevronDown width="24" height="24" className="cursor-pointer" />
          )}
        </div>
      </div>
      <div className={`${open ? content : 'hidden'}`}>{children}</div>
    </div>
  ) : (
    <div key={key} className={outer}>
      <div className={headerRootClassName} onClick={(e) => toggle(!open)}>
        <div className={header}>
          {typeof titleRenderer === 'function' ? (
            titleRenderer(title)
          ) : (
            <h3 className={h3Style}>{title && <span>{title}</span>}</h3>
          )}
          {popover}
        </div>
        {IconsContainer ? (
          <IconsContainer state={open} onChangeState={toggle} />
        ) : (
          <div>
            {open ? (
              <ChevronUp width="24" height="24" className="cursor-pointer" />
            ) : (
              <ChevronDown width="24" height="24" className="cursor-pointer" />
            )}
          </div>
        )}
      </div>
      <div className={`${open ? content : 'hidden'}`}>{children}</div>
    </div>
  )
}
