import React from 'react'
import Summary from './Summary'
import ContentGrid from './ContentGrid'
import ColorBlocks from './ColorBlocks'
import CollapsibleBlock from './CollapsibleBlock'

const ItemCollectionBlock = blockProps => {
  return (
    <>
      {
        // TODO: Object is constructed on each iteration. It should be imported. PH-2825
        {
          'Content Grid': <ContentGrid {...blockProps} />,
          'Color Blocks': <ColorBlocks {...blockProps} />,
          'Summary': <Summary {...blockProps} />,
          'Collapsible': <CollapsibleBlock {...blockProps} />
        }[blockProps.style]
      }
    </>
  )
}

export default ItemCollectionBlock
