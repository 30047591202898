import React from 'react'
import PropTypes from 'prop-types'
import ImageBlock from './Blocks/ImageBlock'
import TextCtaBlock from './Blocks/TextCtaBlock'
import EmbeddedSnippetBlock from './Blocks/EmbeddedSnippetBlock'
import ItemCollectionBlock from './Blocks/ItemCollectionBlock/ItemCollectionBlock'
import CardWrapper from '../CardWrapper'

const MIN_BLOCK_COUNT = 2

const BasicCard = ({
  swapMobileOrder = false,
  isDesktopFullWidth = false,
  blocks,
  ...wrapperProps
}) => {
  // Cards with only one block should still be split in half
  const blockCount = blocks
    ? blocks.length < MIN_BLOCK_COUNT
      ? MIN_BLOCK_COUNT
      : blocks.length
    : MIN_BLOCK_COUNT

  return (
    <CardWrapper {...wrapperProps}>
      <div
        className={`flex justify-center ${
          swapMobileOrder ? 'flex-wrap' : 'flex-wrap-reverse'
        }`}
      >
        {blocks?.map((block) => (
          <div
            key={block.id}
            className={`w-full ${
              !isDesktopFullWidth ? `md:w-1/${blockCount}` : ''
            }`}
          >
            {
              // TODO: Object is constructed on each iteration. It should be imported. PH-2825
              {
                ContentfulImageBlock: <ImageBlock {...block} />,
                ContentfulTextCtaBlock: <TextCtaBlock {...block} />,
                ContentfulItemCollectionBlock: (
                  <ItemCollectionBlock
                    {...block}
                    isDesktopFullWidth={isDesktopFullWidth}
                  />
                ),
                ContentfulEmbeddedSnippetBlock: (
                  <EmbeddedSnippetBlock {...block} />
                )
              }[block.__typename]
            }
          </div>
        ))}
      </div>
    </CardWrapper>
  )
}

BasicCard.propTypes = {
  swapMobileOrder: PropTypes.bool,
  isDesktopFullWidth: PropTypes.bool,
  blocks: PropTypes.array
}

export default BasicCard
