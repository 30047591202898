import React from 'react'
import RichText from '../../../../utils/richTextUtils'
import Image from 'gatsby-image'
import LandingCta from '../../common/LandingCta'
import PropTypes from 'prop-types'

const TextBelowHeroCard = ({
  desktopImage,
  mobileImage,
  headlineText,
  bodyRichText,
  cta
}) => {
  return (
    <div className="flex flex-wrap w-full bg-color-pale-grey">
      <div className="w-full md:w-1/2">
        <Image
          className="sm:block hidden"
          fluid={desktopImage.fluid}
          alt={desktopImage.title}
        />
        {mobileImage && (
          <Image
            className="sm:hidden"
            fluid={mobileImage.fluid}
            alt={mobileImage.title}
          />
        )}
      </div>
      <div className="w-full md:w-1/2 px-6 pt-6 pb-10 lg:p-10 flex flex-col justify-center items-start">
        {headlineText && <h1>{headlineText}</h1>}
        {bodyRichText && <RichText richTextDocument={bodyRichText.json} />}
        {cta && (
          <div className="pt-4">
            <LandingCta {...cta} />
          </div>
        )}
      </div>
    </div>
  )
}

TextBelowHeroCard.propTypes = {
  desktopImage: PropTypes.object.isRequired,
  mobileImage: PropTypes.object,
  headlineText: PropTypes.string,
  bodyRichText: PropTypes.object,
  cta: PropTypes.object
}
export default TextBelowHeroCard
