import React from 'react'
import PropTypes from 'prop-types'
import EmbedVideo from '../../../../common/EmbedVideo'

const EmbeddedSnippetBlock = ({ type, snippetId }) => {
  return (
    <>
      {
        // TODO: Object is constructed on each iteration. It should be imported. PH-2825
        {
          Klaviyo: <div className={`${snippetId} mx-auto`} />,
          Wistia: (
            <div className="md:pb-6">
              <EmbedVideo videoId={snippetId} autoplay={false} />
            </div>
          )
        }[type]
      }
    </>
  )
}

EmbeddedSnippetBlock.propTypes = {
  props: PropTypes.shape({
    type: PropTypes.oneOf(['Klaviyo', 'Wistia']).isRequired,
    snippetId: PropTypes.string.isRequired
  })
}

export default EmbeddedSnippetBlock
