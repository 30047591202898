import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import PropTypes from 'prop-types'
import DynamicHeroCardText from './DynamicHeroCardText'

const TextOverlayHeroCard = ({
  id,
  headlineText,
  appendDynamicConditions,
  bodyText,
  fullWidthBodyText,
  desktopImage,
  mobileImage,
  cta
}) => {
  return (
    <>
      <div className="w-full" data-contentfulid={id}>
        <BackgroundImage
          className="sm:block hidden"
          fluid={desktopImage.fluid}
          alt={desktopImage.title}
        >
          {
            <>
              <DynamicHeroCardText
                headlineText={headlineText}
                isDynamic={appendDynamicConditions}
                bodyText={bodyText}
                cta={cta}
              />
            </>
          }
        </BackgroundImage>
        {mobileImage && (
          <BackgroundImage
            className="sm:hidden hero-height-75-vh"
            fluid={mobileImage.fluid}
            alt={mobileImage.title}
          >
            {
              <>
                <DynamicHeroCardText
                  headlineText={headlineText}
                  isDynamic={appendDynamicConditions}
                  bodyText={bodyText}
                  fullWidthBodyText={fullWidthBodyText}
                  cta={cta}
                />
              </>
            }
          </BackgroundImage>
        )}
      </div>
    </>
  )
}

TextOverlayHeroCard.propTypes = {
  id: PropTypes.string.isRequired,
  headlineText: PropTypes.string,
  bodyText: PropTypes.string,
  fullWidthBodyText: PropTypes.bool,
  desktopImage: PropTypes.object.isRequired,
  mobileImage: PropTypes.object.isRequired,
  cta: PropTypes.object
}

export default TextOverlayHeroCard
