import React from 'react'
import PropTypes from 'prop-types'
import LandingCta from '../../../../common/LandingCta'
import TextSnippet from './TextSnippet'

const MAX_COLUMN_COUNT = 3
const MIN_COLUMN_COUNT = 2

const ContentGrid = ({ headline, items = {}, isDesktopFullWidth = false }) => {
  let columnCount = items.length

  if (columnCount > MAX_COLUMN_COUNT) {
    columnCount =
      columnCount % MAX_COLUMN_COUNT > 0 ? MIN_COLUMN_COUNT : MAX_COLUMN_COUNT
  }

  const desktopWidth = isDesktopFullWidth ? `w-1/${columnCount}` : 'w-full'

  const isCtaGrid = items[0]?.__typename === 'ContentfulCta'

  return (
    <div className="mb-6">
      {headline && <h3 className="pb-6 text-center">{headline}</h3>}
      <div
        className={`${
          isCtaGrid ? `condition__group` : `flex flex-wrap lg:px-32`
        }`}
      >
        {items?.map((item) => (
          <div
            className={`w-full md:${desktopWidth} ${isCtaGrid ? 'flex' : ''}`}
            key={item.id}
          >
            {
              // TODO: Object is constructed on each iteration. It should be imported. PH-2825
              {
                ContentfulTextSnippet: <TextSnippet {...item} />,
                ContentfulCta: <LandingCta {...item} />
              }[item.__typename]
            }
          </div>
        ))}
      </div>
    </div>
  )
}

ContentGrid.propTypes = {
  items: PropTypes.array.isRequired,
  isDesktopFullWidth: PropTypes.bool
}

export default ContentGrid
