export const COLORS = {
  WHITE: 'white',
  PALE_PURPLE: 'pale-purple',
  LILAC: 'lilac',
  PALE_GREY: 'pale-grey',
  LIGHT_GREY: 'light-grey',
  LIGHT_TEAL: 'light-teal',
  PEACH: 'peach',
  VIOLET: 'violet-30',
  GRAPEFRUIT: 'grapefruit-30'
}

export const setPieChartColors = (data, sorted) => {
  const purpleColors = [
    {
      bg: '#736B9C',
      font: '#ffffff'
    },
    {
      bg: '#9B95B8',
      font: '#171523'
    },
    {
      bg: '#C3BFD4',
      font: '#171523'
    },
    {
      bg: '#EBE9F0',
      font: '#171523'
    }
  ]

  const updatedData = Object.values(data).reduce((acc, curr) => {
    sorted.forEach((item, index) => {
      if (curr.value === item.value && curr.id === item.id) {
        //The purpleColors[0] here is due to a case I accedently came up on quiz.
        // Undefined color makes the whole site to hang, so just in case I add a fallback value here
        acc[curr.id] = {
          id: curr.id,
          value: curr.value,
          color: purpleColors[index] || purpleColors[0]
        }
      }
    })
    return acc
  }, {})

  return updatedData
}
