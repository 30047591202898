import React from 'react'
import Button from '../../common/Button'
import { navigate } from 'gatsby'
import DynamicCTA from '../../common/DynamicQuizPlanCTA'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import { conditionSlugs } from 'goodpath-common'

const DYNAMIC_QUIZ_PLAN = 'Dynamic Quiz/Plan'
const STATIC = 'Static'
const IMAGE = 'Image'

export default function LandingCta({
  type = STATIC,
  style = 'primary',
  condition = 'home',
  text = '',
  image,
  slug
}) {
  const renderCta = (type) => {
    switch (type) {
      case DYNAMIC_QUIZ_PLAN:
        return (
          <DynamicCTA
            variant={style}
            condition={condition}
            slug={slug}
            text={text}
          />
        )
      case IMAGE:
        return (
          <div className="inline-block w-full">
            <a href={`/${slug}`}>
              <div className="condition__button">
                <Image
                  className="condition__image"
                  fluid={image.fluid}
                  alt={image.title}
                />
                {text && <h3 className="condition__text">{text}</h3>}
              </div>
            </a>
          </div>
        )
      default:
        return (
          <Button variant={style} onClick={() => navigate(slug)}>
            {text}
          </Button>
        )
    }
  }

  return <>{renderCta(type)}</>
}

LandingCta.propTypes = {
  Type: PropTypes.oneOf([DYNAMIC_QUIZ_PLAN, STATIC, IMAGE]),
  Condition: PropTypes.oneOf(['home'].concat(conditionSlugs)),
  Style: PropTypes.oneOf(['primary', 'secondary', 'link', 'image']),
  Text: PropTypes.string,
  Image: PropTypes.object,
  Slug: PropTypes.string
}
