import React from 'react'
import RichText from '../../../../../../utils/richTextUtils'
import { COLORS } from '../../../../../../constants/colors'

const COLOR_BLOCK_COLORS = [COLORS.LIGHT_TEAL, COLORS.LILAC, COLORS.PEACH]
const COLOR_COUNT = 3

// ToDo make this work for testimonials too
const ColorBlocks = ({ items }) => {
  return (
    <div className="testimonial--container">
      {items?.map((item, index) => {
        const blockColor = COLOR_BLOCK_COLORS[index % COLOR_COUNT]
        return (
          <div key={item.id} className={`testimonial bg-color-${blockColor}`}>
            {item.headlineText && <h5>{item.headlineText}</h5>}
            <RichText richTextDocument={item.bodyText.json} />
          </div>
        )
      })}
    </div>
  )
}

export default ColorBlocks
