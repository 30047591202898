import React from 'react'
import Img from 'gatsby-image'
import RichText from '../../../../../../utils/richTextUtils'
import PropTypes from 'prop-types'
import checkmarkBullet from '../../../../../../images/styleguide/utility/checkmark-bullet.svg'

const Summary = ({ items, headline, image }) => {
  return (
    <div className="px-6">
      <div className="summary__graphic">
        {image && (
          <Img
            className="w-16 mx-auto summary__avatar"
            fluid={image.fluid}
            alt={image.title}
          />
        )}
        {headline && headline}
      </div>
      <div className="summary">
        {items?.map((item) => {
          return (
            <div className="summary__item" key={item.id}>
              <div className="summary__item--icon">
                <img src={checkmarkBullet} alt="checkmark" />
              </div>
              <div className="summary_item--text">
                <RichText richTextDocument={item.bodyText.json} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

Summary.propTypes = {
  items: PropTypes.array.isRequired,
  headline: PropTypes.string,
  image: PropTypes.object
}

export default Summary
