import React from 'react'
import Collapsible from '../../../../../common/Collapsible'
import documentToReactComponents from '../../../../../../utils/richTextUtils'

const CollapsibleBlock = ({ items }) => {
  const Divider = () => <div className="border-b border-grey-light pt-2 mb-4" />
  const headerClasses =
    'text-lg flex flex-row w-full my-4 leading-snug font-bold text-black'

  return (
    <div className="w-full max-w-5xl px-6 lg:mx-auto">
      {items?.map((item, index) => (
        <div key={item.id}>
          <Collapsible
            title={item.headlineText}
            styles={{ header: headerClasses }}
          >
            <div className="leading-relaxed mb-6">
              {documentToReactComponents({
                richTextDocument: item.bodyText.json
              })}
            </div>
          </Collapsible>
          {index === items.length - 1 ? <div className="my-4" /> : <Divider />}
        </div>
      ))}
    </div>
  )
}

export default CollapsibleBlock
