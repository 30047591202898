import React from 'react'
import RichText from '../../../../../../utils/richTextUtils'
import Image from 'gatsby-image'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const LEFT = 'Left'
const RIGHT = 'Right'
const CENTER = 'Center'

const TextSnippet = ({ alignment = CENTER, headlineText, bodyText, icon }) => {
  const wrapperClass = classNames({
    'flex flex-col items-center mb-6 lg:mb-6': alignment === CENTER,
    'flex mb-6 lg:mb-16 ml-4': alignment !== CENTER
  })
  const iconClass = classNames({
    'w-32 h-32': alignment === CENTER,
    'h-16 w-16 mx-2': alignment !== CENTER
  })
  const textClass = classNames({
    'mt-2 px-8': alignment === CENTER,
    'w-3/4 pr-4 lg:px-6 mx-4': alignment !== CENTER
  })

  return (
    <div className={wrapperClass}>
      {icon && (
        <div className={iconClass}>
          <Image fluid={icon.fluid} alt={icon.title} />
        </div>
      )}
      <div className={`${textClass} text-${alignment.toLowerCase()}`}>
        {headlineText && <h3>{headlineText}</h3>}
        {bodyText && <RichText richTextDocument={bodyText.json} />}
      </div>
    </div>
  )
}

TextSnippet.propTypes = {
  alignment: PropTypes.oneOf([LEFT, RIGHT, CENTER]),
  headlineText: PropTypes.string,
  bodyText: PropTypes.object,
  icon: PropTypes.object
}

export default TextSnippet
