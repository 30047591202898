import React from 'react'
import TextOverlayHeroCard from './TextOverlayHeroCard'
import TextBelowHeroCard from './TextBelowHeroCard'

// ToDo make the text below component card
const HeroCardContainer = cardProps => {
  return (
    <>
      {
        // TODO: Object is constructed on each iteration. It should be imported. PH-2825
        {
          'Text Overlay': <TextOverlayHeroCard {...cardProps} />,
          'Text Below': <TextBelowHeroCard {...cardProps} />
        }[cardProps.style]
      }
    </>
  )
}

export default HeroCardContainer
